@import '../../utils/utils';

.canvas {
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  max-height: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .error {
    position: absolute;
    color: white;
    filter: drop-shadow(-1px 1px 2px rgb(0 0 0 / 0.5));
    left: 0;
    margin: 0.5rem;
  }

  @media ($mq-mobile) {
    max-height: calc(100% - 128px);
  }

  .loading-overlay {
    $time: 0.3s;
    position: fixed;
    z-index: 9;
    background: rgb(0 0 0 / 0.5);
    backdrop-filter: blur(10px);
    text-shadow: 1px 1px 15px rgb(0 0 0 / 0.9);
    transition: opacity $time linear;
    &.hidden {
      transition: visibility 0s $time, opacity $time linear;
    }

    & img {
      width: 20%;
      max-width: 40px;
    }

    &::after {
      content: 'Working on it...';
      color: var(--primary);
      margin: 0 1rem;
    }
  }
}
