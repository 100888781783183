@import '../../utils/utils';

.feedback {
  padding: 0 24px 84px;
  font-family: var(--inter);

  .modal {
    margin: 0;
    width: 420px;
    max-width: 100%;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 0;
  }

  button {
    @include button-reset;
  }

  .ratings {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    font-size: 12px;

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding: 0 11px 6px;
      button {
        $size: 24px;
        width: $size;
        height: $size;
        border-radius: $size;
        line-height: $size;
        border: 1px solid var(--contrastLow);
        background-color: var(--surface);
        &:hover,
        &.active {
          color: var(--surface);
          border-color: var(--contrastLow);
          background-color: var(--contrastLow);
        }
      }
    }
    .labels {
      width: 94%;
      display: flex;
      justify-content: space-between;
      span {
        width: 70px;
        text-align: center;
      }
    }
  }

  .input {
    box-sizing: border-box;
    background: var(--surfaceAlt);
    width: 100%;
    border: 1px solid var(--secondaryAlt);
    border-radius: 6px;
    padding: 6px 8px;
    color: var(--contrastLow);
    font-family: var(--inter);
    &::placeholder {
      color: var(--contrastLow);
      opacity: 0.7;
    }
    &:placeholder-shown + button.send-feedback {
      pointer-events: none;
      opacity: 0.2;
      filter: saturate(0.5);
    }
  }
  textarea {
    min-height: 100px;
  }
  button.btn.send-feedback {
    position: relative;
    float: right;
    translate: -5px -120%;
    padding: 7px;
    transition: all 0.3s;
    opacity: 1;
    filter: saturate(1);
    align-self: flex-end;
    svg {
      margin: 0;
    }
  }

  .email {
    position: relative;
    display: flex;
    input {
      line-height: 30px;
      &:invalid + button.send-email {
        pointer-events: none;
        opacity: 0.2;
        filter: saturate(0.5);
      }
    }
    button {
      position: absolute;
      right: 12px;
      top: 0;
      bottom: 0;
      padding: 10px 0;
    }
  }

  .sign-up {
    color: var(--contrastLow);
    text-decoration: none;
    margin: auto;
    display: block;
    width: fit-content;
    color: white;
    font-size: 14px;
    padding: 2rem 1rem;
  }
}
