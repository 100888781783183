@import '../../utils/utils';

.with-seperator {
  margin-top: 1.5em;
  border-top: 1px solid var(--secondaryAlt);
  padding-top: 0.5em;
}

.background-panel {
  padding: 0 1em;
  .reset-bgs {
    @include button-reset;
    position: absolute;
    right: 2rem;
    padding: 0.5em 1em;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
  .backgrounds {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.5em;
    .background {
      position: relative;
      border-radius: 8px;
      background-color: black;
      overflow: hidden;
      button {
        @include button-reset;
      }
      &.active {
        outline: 2px solid var(--primary);
      }
      &:hover {
        .remove {
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .remove {
      position: absolute;
      opacity: 0;
      top: 0;
      z-index: 2;
      color: white;
      filter: drop-shadow(-1px 1px 2px rgb(0 0 0 / 0.5));
    }
    .select {
      aspect-ratio: 3/2;
      overflow: hidden;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.none {
        @include checkered(30px);
      }
      &:hover img {
        filter: contrast(140%);
      }
    }
    img {
      vertical-align: top;
      max-width: 100%;
      height: auto;
      object-fit: cover;
      transition: all 0.1s ease-in-out;
    }
  }

  .shadow {
    @extend .with-seperator;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -1em;
    margin-top: 1em;
  }

  .generative {
    textarea {
      box-sizing: border-box;
      background: var(--surfaceAlt);
      width: 100% !important;
      min-height: 4rem;
      border: 1px solid var(--secondaryAlt);
      border-radius: 6px;
      padding: 6px 8px;
      color: white;
      font-family: var(--inter);
      &:focus {
        outline: none;
        border-color: var(--primary);
      }
      &:placeholder-shown ~ button.generate {
        opacity: 0.8;
        color: rgb(9 12 15 / 40%);
      }
      ~ button {
        padding: 8px;
      }
    }
    .generate {
      width: calc(100% - 3rem);
      float: right;
    }
    .random {
      background: rgb(83 95 122 / 60%);
      fill: var(--contrastLow);
      svg {
        margin: 0 2px;
      }
    }
  }

  .upload {
    .dropzone {
      aspect-ratio: 3/2;
      font-size: 12px;
      padding: 10px;
    }
  }
}
