@import '../../utils/utils';

.coming-soon {
  padding: 0 1em;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: bold;
  }

  .sign-up-title {
    margin-top: 2rem;
    padding: 0;
  }

  h5 {
    font-weight: normal;
    margin: 0 0 0.5rem;
  }

  .input {
    box-sizing: border-box;
    background: var(--surfaceAlt);
    width: 100%;
    border: 1px solid var(--secondaryAlt);
    border-radius: 6px;
    padding: 6px 8px;
    color: var(--contrastLow);
    font-family: var(--inter);
    &::placeholder {
      color: var(--contrastLow);
      opacity: 0.7;
    }
  }
  .email {
    position: relative;
    display: flex;
    input {
      line-height: 30px;
      &:invalid + button.send-email {
        pointer-events: none;
        opacity: 0.2;
        filter: saturate(0.5);
      }
    }
    button {
      position: absolute;
      right: 12px;
      top: 0;
      bottom: 0;
      padding: 10px 0;
    }
  }

  .sign-up {
    color: var(--contrastLow);
    text-decoration: none;
    margin: auto;
    display: block;
    width: fit-content;
    color: white;
    font-size: 14px;
    padding: 2rem 1rem;
  }

}
