@use "sass:math";

$mq-desktop: "min-width: 1080px";
$mq-mobile: "max-width: 1079px";

@mixin button-reset {
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

@mixin checkered($size: 40px, $darkness1: 0.4, $darkness2: 0.7) {
  $color1: rgb(0, 0, 0, $darkness1);
  $color2: rgb(0, 0, 0, $darkness2);
  background: linear-gradient(
      45deg,
      $color2 25%,
      $color1 25%,
      $color1 75%,
      $color2 75%,
      $color2 0
    ),
    linear-gradient(
      45deg,
      $color2 25%,
      $color1 25%,
      $color1 75%,
      $color2 75%,
      $color2 0
    ),
    white;
  background-repeat: repeat, repeat;
  background-position: 0px 0, math.div($size, 2) math.div($size, 2);
  background-size: $size $size, $size $size;
  background-origin: padding-box, padding-box;
  transform-origin: 0 0 0;
}

@mixin hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}