@import '../../utils/utils';

.footer {
  font-family: var(--inter);
  background-color: var(--surfaceAlt);
  z-index: 1;
  width: 100%;

  .footer-inner {
    align-items: center;
    display: flex;
    max-width: 1080px;
    margin: 0px auto;
    padding: 5px;
    justify-content: space-evenly;
  }

  button {
    @include button-reset;
    aspect-ratio: 1;

    &.btn svg {
      margin: 0;
    }

    &.btn.active {
      color: #28bbfa;
      background-color: #1a262c;
      border-radius: 100%;
      path {
        fill: #28bbfa;
      }
    }
  }

  a, button {
    color: var(--primary);
    font-weight: 600;
    text-decoration: none;
  }

  .label {
    @media ($mq-mobile) {
      display: none;
    }
  }
}
