@import '../../utils/utils';

.with-seperator {
  margin-top: 1.5em;
  border-top: 1px solid var(--secondaryAlt);
  padding-top: 0.5em;
}

.overlay-panel {
  padding: 0 1em;
  .reset-overlays {
    @include button-reset;
    position: absolute;
    right: 2rem;
    padding: 0.5em 1em;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
  .overlays {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.5em;
    .overlay {
      position: relative;
      border-radius: 8px;
      background-color: black;
      overflow: hidden;
      button {
        @include button-reset;
      }
      &.active {
        outline: 2px solid var(--primary);
      }
      &:hover {
        .remove {
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .remove {
      position: absolute;
      opacity: 0;
      top: 0;
      z-index: 2;
      color: white;
      filter: drop-shadow(-1px 1px 2px rgb(0 0 0 / 0.5));
    }
    .select {
      aspect-ratio: 3/2;
      overflow: hidden;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.none {
        @include checkered(30px);
      }
      &:hover img {
        filter: contrast(140%);
      }
    }
    img {
      vertical-align: top;
      max-width: 100%;
      height: auto;
      object-fit: cover;
      transition: all 0.1s ease-in-out;
    }
  }
  &.none {
    @include checkered(30px);
  }
  .field-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;
  }
  .upload {
    .dropzone {
      aspect-ratio: 3/2;
      font-size: 12px;
      padding: 10px;
    }
  }
}
