@import '../../utils/utils';

.share {
  .modal-content {
    min-width: 300px;
    padding-bottom: 1.5em;
  }
  .share-img {
    max-width: 100%;
    height: auto;
  }
  .share-buttons {
    width: 100%;
    display: flex;
    margin: 15px 0;
    justify-content: space-around;
  }
  .share-url {
    border: 1px solid var(--secondaryAlt);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.2em;
    input {
      flex: 1;
      background: none;
      border: none;
      color: var(--primary)
    }
    button {
      @include button-reset;
      padding: 0.4em 0.3em 0.1em;
    }
  }
}