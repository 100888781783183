.loading-overlay {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 0.7);
  justify-content: center;
  align-items: center;
  z-index: 1;
  display: flex;
  visibility: visible;
  opacity: 1;

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}

img.fade-in-out {
  animation: fadeInOut 2s ease-in-out infinite;
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
