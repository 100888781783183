@import '../../utils/utils';

.color-picker {
  position: relative;
  height: 100%;
  border-radius: 8px;
  > button {
    @include button-reset;
    position: absolute;
  }

  &.active {
    outline: 2px solid var(--primary);
  }
  &:hover {
    .open-modal {
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }

  .set {
    font-size: 0.9rem;
    padding: 0 10px;
    line-height: 1.4;
    text-shadow: 1px 1px 5px rgb(0 0 0 / 0.6);
    inset: 0;
  }

  .color-picker-button {
    width: 100%;
    height: 100%;
  }
}

.color-picker-modal {
  .modal {
    margin: 0;
    width: 420px;
    max-width: 100%;
    padding-bottom: 1rem;
    text-shadow: 1px 1px 15px rgb(0 0 0 / 0.9);
    color: var(--contrastHigh);

    .modal-content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .color-pickers-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
      }
      .color-preview {
        height: 90px;
      }

      .picker-button {
        @include button-reset;

        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;

        > input {
          position: absolute;
          width: 40px;
          height: 40px;
          visibility: hidden;
        }
      }

      .color-preview {
        border-radius: 4px;
      }

      .color-picker-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
