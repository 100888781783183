@import '../../utils/utils';

.editor {
  flex: 1 1;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 100%;
  @include checkered();

  @media ($mq-mobile) {
    flex-direction: column;
  }
}
