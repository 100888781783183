@import '../../utils/utils';

.download {
  padding: 0 24px 84px;
  font-family: var(--inter);

  .modal {
    margin: 0;
    width: 420px;
    max-width: 100%;
  }

  .images {
    display: flex;
    position: relative;
    justify-content: flex-end;
    overflow: hidden;
    img {
      max-width: 50%;
      max-height: 300px;
      margin-left: -30%;
      border-radius: 6px;
      box-shadow: 1px 1px 15px 1px var(--surfaceAlt);
      object-fit: cover;
      @include checkered(20px);
    }
  }

  h3 {
    margin-top: 2em;
    font-weight: 500;
    @media ($mq-mobile) {
      margin: 0;
      padding: 1.1em 0;
    }
  }

  .input {
    box-sizing: border-box;
    background: var(--surfaceAlt);
    width: 100%;
    border: 1px solid var(--secondaryAlt);
    border-radius: 6px;
    padding: 6px 8px;
    color: var(--contrastLow);
    font-family: var(--inter);
    &::placeholder {
      color: var(--contrastLow);
      opacity: 0.7;
    }
    &:placeholder-shown + button.send-feedback {
      pointer-events: none;
      opacity: 0.2;
      filter: saturate(0.5);
    }
  }

  .email {
    position: relative;
    display: flex;
    input {
      line-height: 30px;
      &:invalid + button.send-email {
        pointer-events: none;
        opacity: 0.2;
        filter: saturate(0.5);
      }
    }
    button {
      @include button-reset;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 10px 0;
    }
  }

  .agree {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 2em;

    input {
      margin: 0;
      appearance: none;

      ~ .checked {
        display: none;
        color: var(--primary);
      }
      &:checked {
        ~ .checked {
          display: inline;
        }
        ~ .unchecked {
          display: none;
        }
      }
    }
    svg {
      margin-right: 0.5em;
    }
    span {
      display: inline-block;
    }
    a {
      color: var(--contrastLow);
      text-decoration: none;
      font-weight: 700;
    }
  }

  .actions {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
  }
}
