@import '../../utils/utils';

.assets {
  @include hide-scrollbar;
  background-color: var(--surfaceAlt);
  box-sizing: border-box;
  width: inherit;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
  margin-bottom: 0;
  overflow: auto;

  > .asset {
    border-radius: 8px;
    background-color: black;
    position: relative;
    overflow: hidden;
    aspect-ratio: 4/3;

    &.active {
      outline: 2px solid var(--primary);
    }
  }

  button {
    @include button-reset;

    &.select {
      aspect-ratio: 4/3;
    }

    &.remove {
      position: absolute;
      z-index: 2;
      opacity: 0;
      top: 0;
      color: white;
      filter: drop-shadow(-1px 1px 2px rgb(0 0 0 / 0.5));
    }
  }
  .asset:hover {
    .remove {
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }

  img {
    vertical-align: top;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    object-fit: contain;
  }

  .dropzone {
    font-size: 13px;
    font-weight: bold;
    border-width: 2px;
    padding: 5px 20px;
  }

  @media ($mq-mobile) {
    min-width: 100%;
    flex-direction: row;

    > * {
      flex: 0 0 140px; // width
    }
  }
  @media ($mq-desktop) {
    height: 100%;

    > * {
      flex: 0 0 135px; // height
    }
  }
}
