.placement-picker-container {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;

  .middle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
  }

  .placement-matrix {
    border: 1px solid var(--secondaryAlt);
    border-radius: 0.5em;
    height: 100px;
    width: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    .circle-container {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        .circle {
          height: 1em;
          width: 1em;
        }
      }
      .circle {
        height: 0.5em;
        width: 0.5em;
        border-radius: 50%;
        background-color: var(--secondaryAlt);
        &.active {
          height: 1em;
          width: 1em;
          background-color: var(--contrastLow);
        }
      }
    }
  }
}
