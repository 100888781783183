@import '../../utils/utils';

:root {
  --primary: #0d9aff;
  --background: #090c0f;
  --surface: #1f242e;
  --surfaceAlt: #14181e;
  --contrastLow: #d1d6e0;
  --contrastHigh: #f9fafb;
  --secondary: #3d475c;
  --secondaryAlt: #535f7a;
  --sofia-pro: 'sofia-pro', sans-serif;
  --inter: 'Inter', sans-serif;
}

body {
  margin: 0;
  font-family: var(--inter);
  color: var(--contrastLow);
  background-color: var(--surface);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
secondaryAlt img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

.App {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 0;

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    height: 100vh;
    max-width: 100%;
    transition: max-width 0.2s, min-height 0.2s;
  }

  .content {
    width: 100%;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .btn {
    @extend .btn;
  }
}

h3 {
  margin: 0;
}

// Buttons
.btn {
  @include button-reset;
  background-color: var(--primary);
  color: var(--background);
  font-size: 14px;
  padding: 10px 14px;
  font-weight: 700;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.25s;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
    filter: brightness(1.1);
  }

  &[disabled] {
    opacity: 0.5;
  }

  svg {
    vertical-align: middle;
  }
  &.with-text svg {
    margin-right: 0.5em;
    margin-left: -0.25em;
  }

  &.inverted {
    background-color: transparent;
    color: var(--primary);
  }

  &.light {
    color: var(--contrastLow);
  }
}
.apply-btn {
  background-color: var(--secondary) !important;
  color: var(--contrastLow) !important;
  font-weight: 400 !important;
}
