.range-input {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 15rem;
  flex: 1;
  background: var(--secondaryAlt);
  background-image: linear-gradient(var(--primary), var(--primary));
  border-radius: 4px;
  background-size: 50% 100%;
  background-repeat: no-repeat;
  &::-webkit-slider-runnable-track {
    background: transparent;
    height: 4px;
  }
  &::-moz-range-track {
    background: transparent;
    height: 4px;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -0.5em; /* Centers thumb on the track */
    background-color: var(--surfaceAlt);
    border: 2px solid var(--primary);
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
  }
  &::-moz-range-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -0.5em; /* Centers thumb on the track */
    background-color: var(--surfaceAlt);
    border: 2px solid var(--primary);
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
  }
}
