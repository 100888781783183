@import '../../utils/utils';

.modal-overlay {
  position: fixed;
  z-index: 9;
  inset: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(10 12 15 / 0.6);
  backdrop-filter: blur(1.5rem);
}
.modal {
  border: 1px solid rgba(white, 0.2);
  font-size: 14px;
  padding: 0 1.25rem;
  max-height: -webkit-fill-available;
  max-width: calc(100vw - 2rem);
  border-radius: 5px;
  background-color: var(--surface);
  margin: 1rem;
  box-shadow: rgb(0 0 0 / 0.2) 0px 2px 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-width: 120px;

  button.modal-close {
    @include button-reset;
    float: right;
    margin: 1rem 0;
    svg {
      margin: 0;
    }
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
}
