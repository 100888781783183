@import '../../utils/utils';

.sidepanel {
  position: relative;
  z-index: 3;
  overflow: auto;
  box-sizing: border-box;
  color: white;
  background-color: var(--surfaceAlt);
  height: 100%;
  flex: 0 0 0;
  width: 0;
  opacity: 0;
  transition: all 0.2s;

  &.open {
    flex: 0 0 440px;
    opacity: 1;
  }

  @media ($mq-mobile) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    max-width: 100%;
    &.open {
      width: 440px;
    }
  }

  .sidepanel-toggle {
    @include button-reset;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 8;
    padding: 0.5em 1em 0.5em 0.2em;
    svg {
      width: 14px;
      height: 14px;
    }
    &, svg {
      transition: all .25s;
    }
    &:hover svg {
      transform: rotate(90deg);
    }
  }

  h3 {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    line-height: 30px;
    padding: 0.5em 0;
  }
}
