@import '../../utils/utils';

.dropzone {
  box-sizing: border-box;
  border: 2px dashed var(--secondaryAlt);
  background-color: var(--surface);
  color: var(--contrastLow);
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  font-family: var(--inter);
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  aspect-ratio: 600/300;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .error {
    font-size: 80%;
    margin-top: 0;
    color: #ff5722
  }
  p {
    margin: 0;
  }
  &:hover {
    border-color: var(--contrastLow);
  }

  &.active {
    border-color: var(--contrastLow);
    background-color: var(--surfaceAlt);
  }
}
