@import "../../utils/utils";

.header {
  background-color: var(--surfaceAlt);
  z-index: 1;
  width: 100%;

  .header-inner {
    align-items: center;
    display: flex;
    margin: 0px auto;
    padding: 1rem;
    justify-content: space-between;
  }

  .brand {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-family: var(--sofia-pro);
    color: var(--contrastLow);
  }

  .brand-logo {
    width: 2rem;
    height: 2rem;
    margin-right: 0.25rem;
  }

  .brand-name {
    font-size: 20px;
    font-weight: 500;
    align-self: baseline;
    color: white;
  }

  .brand-labs {
    font-size: 16px;
    font-weight: 200;
    letter-spacing: 2.5px;
    margin-left: 10px;
  }

  .brand-slogan {
    border-left: 1px solid var(--secondaryAlt);
    margin-left: 0.5rem;
    padding-left: 0.5rem;
  }

  button {
    @include button-reset;
    margin-left: 1.5rem;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    &:first-of-type {
      margin-left: auto;
    }
    &.download {
      padding: 7px 14px;
    }
  }

  @media ($mq-mobile) {
    // button.share,
    button {
      margin-left: 0.5rem;
      margin-right: 0;
    }
    button.feedback {
      margin-left: auto;
    }
    button.download {
      display: none;
    }
  }
}
