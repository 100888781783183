.numeric-input-container {
  display: flex;
  align-items: center;
  background-color: var(--surfaceAlt);
  border: solid 1px var(--secondaryAlt);
  border-radius: 4px;
  height: 2rem;
  padding-left: 0.5em;

  & > input {
    width: 35px;
    background-color: transparent;
    padding: 0.5em;
    color: var(--contrastHigh);
    text-align: center;
    appearance: textfield;
    border: 0;
    padding: 0;
    v &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:focus {
      outline: 0;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  .steps-buttons {
    display: flex;
    flex-direction: column;
    padding-left: 0.5em;
    height: 100%;

    .step-button {
      background-color: transparent;
      border: 0;
      color: var(--contrastHigh);
      padding: 0;
      height: 50%;
      cursor: pointer;
      :hover {
        background-color: var(--secondaryAlt);
      }
    }
  }
}
