@import '../../utils/utils';

.upload-page {
  width: 100%;
  height: 100%;
  @media ($mq-desktop) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .intro,
  .upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px clamp(20px, 60px, 4vw);
    max-width: 100%;
    .container {
      width: 600px;
      max-width: 100%;
      @media ($mq-desktop) {
        width: auto;
        max-width: 600px;
      }
    }
  }

  .intro {
    h1 {
      position: relative;
      font-size: 83px;
      font-size: clamp(36px, 5.5vw, 72px);
      font-weight: 900;
      line-height: 1;
      margin: 0 0 clamp(10px, 1vw, 50px);
    }
    h2 {
      font-size: 45px;
      font-size: clamp(22px, 3.4vw, 45px);
      font-weight: 600;
      line-height: 1;
      margin: 0 0 clamp(10px, 50px, 1vw);
    }
    h3 {
      font-size: 20px;
      line-height: 1.5;
      font-weight: 500;
      margin: 0 0 1rem;
    }
    img {
      width: 480px;
      max-width: 100%;
      position: relative;
      margin: 0 auto;
      display: block;
      z-index: 1;
    }
    .loading-overlay {
      display: none;
    }

    @media ($mq-desktop) {
      color: var(--contrastHigh);
      background-color: var(--primary);
      background: linear-gradient(311.22deg, #FF5050 1.59%, #E64E5C 4.36%, #A74B7E 11.76%, #764897 18.22%, #5246AA 23.77%, #3D45B6 27.46%, #3545BB 31.16%, #222E95 54.25%, #0E1639 93.98%);
      .container {
        align-self: flex-end;
        position: relative;
        &::before {
          content: url('./dots.svg');
          position: absolute;
          top: -30px;
          left: -15px;
        }
        &::after {
          content: url('./circle.svg');
          position: absolute;
          bottom: 5px;
          left: 45px;
        }
      }
      h3 {
        position: relative;
        &::after {
          content: url('./dots-square.svg');
          position: absolute;
          bottom: -150px;
          right: 10px;
        }
      }
    }

    @media ($mq-mobile) {
      h3,
      img {
        display: none;
      }
    }
  }

  .upload {
    @media ($mq-desktop) {
      .container {
        align-self: flex-start;
      }
    }
    @media ($mq-mobile) {
      h4.try {
        display: none;
      }
    }
    h4 {
      align-self: flex-start;
      font-size: 24px;
      font-weight: normal;
      display: block;
    }

    .samples {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      button {
        @include button-reset;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 2px;
        &:hover img {
          filter: contrast(140%);
        }
      }
      img {
        max-width: 33%;
        height: auto;
        transition: all 0.1s ease-in-out;
        aspect-ratio: 180 / 135;
        object-fit: cover;
      }
      .loading-overlay {
        display: none;
      }
    }
  }
}
